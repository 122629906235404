import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * Modify the html, meta and script tags
 */
export const Head = props => {
  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="description" content={props.description} />
      <title>{props.title}</title>
      <script
        src="https://www.google.com/recaptcha/api.js?render=explicit"
        async
        defer
      ></script>
    </Helmet>
  );
};
