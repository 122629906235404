import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './cookie-consent.scss';

const cookieName = 'cookieConsent';

/**
 * Cookie Consent banner displayed fixed at bottom, will set the cookeiConsent cookie.
 * If accepted, then this will inject the google analytics script, to begin collecting user data
 */
export class CookieConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    let value = this.getCookieValue();
    if (!value) {
      this.setState({
        visible: true
      });
    }
  }

  accept = () => {
    this.setCookie(true);
    this.setState({
      visible: false
    });
  };

  decline = () => {
    this.setCookie(false);
    this.setState({
      visible: false
    });
  };

  setCookie(value) {
    document.cookie = `${cookieName}=${value};path=/;max-age=31536000;samesite=strict;`;
  }

  getCookieValue() {
    const cookies = document.cookie ? document.cookie.split('; ') : [];
    for (let cookie of cookies) {
      const [name, attributes] = cookie.split('=');
      if (name === cookieName) {
        const [value] = attributes.split(';');
        return value;
      }
    }
    return null;
  }

  render() {
    const { visible } = this.state;
    return (
      <TransitionGroup component={null}>
        {visible && (
          <CSSTransition
            classNames="consent-transition"
            timeout={{ enter: 500, exit: 500 }}
          >
            <div className="cookie-consent">
              <p>
                The Aphex Group website employs cookies to improve your user
                experience. If you continue on this website, you will be
                providing your consent to our use of cookies.{' '}
                <Link to="/cookie-policy">
                  Find out more <FontAwesomeIcon icon="arrow-right" />
                </Link>
              </p>
              <button onClick={this.decline}>Decline</button>
              <button onClick={this.accept}>OK</button>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
}
